<template>
    <div>
        <div class="grid grid-cols-2 gap-4">
            <div class="col-span-3 bg-grey-7 p-4 rounded">
                <div
                    class="text-center text-black text-base xl:text-xl font-bold font-display mb-4"
                >
                    Average Survey Results by State
                    <v-tooltip right>
                        <template v-slot:activator="{on, attrs}">
                            <fa-icon
                                v-bind="attrs"
                                v-on="on"
                                icon="question-circle"
                                class="ml-2 text-black"
                            ></fa-icon>
                        </template>
                        <span>abc</span>
                    </v-tooltip>
                </div>
                <div>
                    <div class="grid grid-cols-2">
                        <div class="col-span-1 text-center">
                            <div v-if="chartData && chartData.left !== null && chartData.left === Object(chartData.left)">
                                <span
                                    class="text-grey-2 uppercase font-bold text-xs font-display"
                                >Selected Practice(s) Avg.</span>
                                <div ref="myAvgPerCompliance"></div>
                            </div>
                            <div v-else>
                                <h2 class="font-display text-base lg:text-xl text-black text-center mt-6 mb-2">Unable to load this chart!</h2>
                                <p class="text-sm text-grey-1 text-center">Please try again or contact your system administrator.</p>
                            </div>
                        </div>
                        <div class="col-span-1 text-center">
                            <div v-if="chartData && chartData.right !== null && chartData.right !== null && Object(chartData.right)">
                                <span
                                    class="text-grey-2 uppercase font-bold text-xs font-display"
                                >{{title}} avg.</span>
                                <div ref="allAvgPerCompliance"></div>
                            </div>
                            <div v-else>
                                <h2 class="font-display text-base lg:text-xl text-black text-center mt-6 mb-2">Unable to load this chart!</h2>
                                <p class="text-sm text-grey-1 text-center">Please try again or contact your system administrator.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import camelcaseKeys from 'camelcase-keys'
import FileMethods from '@/components/shared/mixins/fileMethods'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import {Chart} from 'frappe-charts/dist/frappe-charts.min.esm'
import { AuthMixin } from "@/components/shared/mixins/authHelpers"

export default {
    name: 'AverageSurveyResultsByState',
    mixins: [FileMethods, MessageDialog, AuthMixin],
    props: {
        chartData: {
            type: Object,
            default: () => {}
        },
        title: {
            type: String,
            default: 'All my practices'
        }
    },
    data: () => ({
        chartSize: 280,
    }),
    mounted(){
        this.drawPractiveAboveBelowChart(this.chartData)
    },
    methods: {
        drawPractiveAboveBelowChart(data) {
            new Chart(this.$refs.myAvgPerCompliance, {
                data: {
                    labels: [
                        'ACT',
                        'NSW',
                        'NT',
                        'QLD',
                        'SA',
                        'TAS',
                        'VIC',
                        'WA'
                    ],
                    datasets: [
                        {
                            name: "Met",
                            chartType: "bar",
                            values: [
                                data.left.act?.met?this.round(data.left.act.met):0,
                                data.left.nsw?.met?this.round(data.left.nsw.met):0,
                                data.left.nt?.met?this.round(data.left.nt.met):0,
                                data.left.qld?.met?this.round(data.left.qld.met):0,
                                data.left.sa?.met?this.round(data.left.sa.met):0,
                                data.left.tas?.met?this.round(data.left.tas.met):0,
                                data.left.vic?.met?this.round(data.left.vic.met):0,
                                data.left.wa?.met?this.round(data.left.wa.met):0
                            ]
                        },
                        {
                            name: "Not Met",
                            chartType: "bar",
                            values: [
                                data.left.act?.notMet?this.round(data.left.act.notMet):0,
                                data.left.nsw?.notMet?this.round(data.left.nsw.notMet):0,
                                data.left.nt?.notMet?this.round(data.left.nt.notMet):0,
                                data.left.qld?.notMet?this.round(data.left.qld.notMet):0,
                                data.left.sa?.notMet?this.round(data.left.sa.notMet):0,
                                data.left.tas?.notMet?this.round(data.left.tas.notMet):0,
                                data.left.vic?.notMet?this.round(data.left.vic.notMet):0,
                                data.left.wa?.notMet?this.round(data.left.wa.notMet):0
                            ]
                        },
                        {
                            name: "N/A",
                            chartType: "bar",
                            values: [
                                data.left.act?.notApplicable?this.round(data.left.act.notApplicable):0,
                                data.left.nsw?.notApplicable?this.round(data.left.nsw.notApplicable):0,
                                data.left.nt?.notApplicable?this.round(data.left.nt.notApplicable):0,
                                data.left.qld?.notApplicable?this.round(data.left.qld.notApplicable):0,
                                data.left.sa?.notApplicable?this.round(data.left.sa.notApplicable):0,
                                data.left.tas?.notApplicable?this.round(data.left.tas.notApplicable):0,
                                data.left.vic?.notApplicable?this.round(data.left.vic.notApplicable):0,
                                data.left.wa?.notApplicable?this.round(data.left.wa.notApplicable):0
                            ]
                        }
                    ]
                },
                type: 'axis-mixed',
                height: this.chartSize,
                colors: [
                    '#29AC64', // tailwind: green
                    '#E80729', // tailwind: red
                    '#FF6A00' // tailwind: risk-matrix-orange-dark
                ],
                barOptions: {
                    stacked: true,
                    spaceRatio: 0.7
                },
                axisOptions: {
                    xAxisMode: 'tick'
                },
                lineOptions: {
                    dotSize: 8 
                },
                tooltipOptions: {
                    formatTooltipX: (d) => (d + "").toUpperCase(),
                    formatTooltipY: (d) => d
                }
            })

            new Chart(this.$refs.allAvgPerCompliance, {
                data: {
                    labels: [
                        'ACT',
                        'NSW',
                        'NT',
                        'QLD',
                        'SA',
                        'TAS',
                        'VIC',
                        'WA'
                    ],
                    datasets: [
                        {
                            name: "Met",
                            chartType: "bar",
                            values: [
                                data.right.act?.met?this.round(data.right.act.met):0,
                                data.right.nsw?.met?this.round(data.right.nsw.met):0,
                                data.right.nt?.met?this.round(data.right.nt.met):0,
                                data.right.qld?.met?this.round(data.right.qld.met):0,
                                data.right.sa?.met?this.round(data.right.sa.met):0,
                                data.right.tas?.met?this.round(data.right.tas.met):0,
                                data.right.vic?.met?this.round(data.right.vic.met):0,
                                data.right.wa?.met?this.round(data.right.wa.met):0
                            ]
                        },
                        {
                            name: "Not Met",
                            chartType: "bar",
                            values: [
                                data.right.act?.notMet?this.round(data.right.act.notMet):0,
                                data.right.nsw?.notMet?this.round(data.right.nsw.notMet):0,
                                data.right.nt?.notMet?this.round(data.right.nt.notMet):0,
                                data.right.qld?.notMet?this.round(data.right.qld.notMet):0,
                                data.right.sa?.notMet?this.round(data.right.sa.notMet):0,
                                data.right.tas?.notMet?this.round(data.right.tas.notMet):0,
                                data.right.vic?.notMet?this.round(data.right.vic.notMet):0,
                                data.right.wa?.notMet?this.round(data.right.wa.notMet):0
                            ]
                        },
                        {
                            name: "N/A",
                            chartType: "bar",
                            values: [
                                data.right.act?.notApplicable?this.round(data.right.act.notApplicable):0,
                                data.right.nsw?.notApplicable?this.round(data.right.nsw.notApplicable):0,
                                data.right.nt?.notApplicable?this.round(data.right.nt.notApplicable):0,
                                data.right.qld?.notApplicable?this.round(data.right.qld.notApplicable):0,
                                data.right.sa?.notApplicable?this.round(data.right.sa.notApplicable):0,
                                data.right.tas?.notApplicable?this.round(data.right.tas.notApplicable):0,
                                data.right.vic?.notApplicable?this.round(data.right.vic.notApplicable):0,
                                data.right.wa?.notApplicable?this.round(data.right.wa.notApplicable):0
                            ]
                        }
                    ]
                },
                type: 'axis-mixed',
                height: this.chartSize,
                colors: [
                    '#29AC64', // tailwind: green
                    '#E80729', // tailwind: red
                    '#FF6A00' // tailwind: risk-matrix-orange-dark
                ],
                barOptions: {
                    stacked: true,
                    spaceRatio: 0.7
                },
                axisOptions: {
                    xAxisMode: 'tick'
                },
                lineOptions: {
                    dotSize: 8
                },
                tooltipOptions: {
                    formatTooltipX: (d) => (d + "").toUpperCase(),
                    formatTooltipY: (d) => d.toFixed(2)
                }
            })
        },
        round(val) {
            return Math.round(val * 10) / 10
        },
    },
    watch: {
        chartData(data) {
            this.drawPractiveAboveBelowChart(data)
        },
    }
}
</script>