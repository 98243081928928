<template>
    <div>
        <div class="flex items-center my-4">
            <BarChartIcon class="text-primary-light w-10 mr-1" />
            <h1 class="text-black text-xl lg:text-2xl">Practice Comparison</h1>
        </div>

        <div class="items-center flex grow-0 mb-6">
            <div class="mx-4 min-w-200 max-w-200">
                <v-autocomplete
                    ref="selectedPractice"
                    hide-details
                    dense
                    :ripple="false"
                    placeholder="Select one"
                    :items="Object.values(practices)"
                    label="Practice name"
                    @focusin.prevent="setFilterType('practiceName')"
                    @change="filterUpdated"
                    :disabled="isDisabledPractice"
                    
                ></v-autocomplete>
            </div>
            <div class="mx-4 flex items-center">
                <div class="min-w-100 max-w-100 mx-2">
                    <v-select
                    ref="selectedState"
                    label="State"
                    hide-details
                    dense
                    :ripple="false"
                    :items="stateList"
                    @focusin.prevent="setFilterType('state')"
                    @change="filterUpdated"
                    :disabled="isDisabledState"
                    ></v-select>
                </div>
                <div class="min-w-100 max-w-100 mx-2">
                    <v-select
                    ref="selectedMMM"
                    label="MMM"
                    hide-details
                    dense
                    :ripple="false"
                    :items="mMMList"
                    @focusin.prevent="setFilterType('mmm')"
                    @change="filterUpdated"
                    :disabled="isDisabledMMM"
                    ></v-select>
                </div>
                <div class="min-w-100 max-w-100 mx-2">
                    <v-select
                    ref="selectedSize"
                    label="Size"
                    hide-details
                    dense
                    :ripple="false"
                    :items="sizeList"
                    @focusin.prevent="setFilterType('size')"
                    @change="filterUpdated"
                    :disabled="isDisabledSize"
                    ></v-select>
                </div>
            </div>
            <div class="min-w-200 max-w-200 mx-2">
                <v-select
                ref="selectedCompare"
                hide-details
                dense
                :ripple="false"
                label="Compare to"
                :items="compareList"
                @focusin.prevent="setFilterType('compare')"
                v-on:change="filterUpdated"
                return-object
                ></v-select>
            </div>
            <div class="min-w-200 max-w-200 mx-4">
                <DataRangePicker
                    label="Date Range"
                    :max-date="today"
                    v-model="dateRange"
                    hide-details
                    dense
                    range
                    :ripple="false"
                />
            </div>
            <div class="mx-4">
                <v-btn
                    class="mr-2"
                    color="primary"
                    depressed
                    :disabled="isDisabledApply"
                    @click="handleApply()"
                >Apply</v-btn>
                <v-btn
                    class=""
                    color="primary"
                    depressed
                    :disabled="isDisabledReset"
                    @focusin.prevent="setFilterType('reset')"
                    @click="filterUpdated"
                >RESET</v-btn>
            </div>
        </div>
        <div>
            <div v-if="errorCode === 404"
                class="text-center"
            >
                <h1 class="text-black text-lg lg:text-2xl text-center mb-3 mt-4">Unable to generate this report for the specified filters due to insufficient data!
                </h1>
                <p>Please select another set of filters and try again.</p>
            </div>
            <div class="flex items-center justify-center h-20 w-full" v-else-if="loading">
                <Loader class="w-12 h-12" />
            </div> 
            <div v-else-if="chartData">
                <div class="grid grid-cols-2 gap-4 mb-6" >
                    <AverageSurveyResults :chartData="chartData.avgCompliance" :title="title"/>
                    <NonConformitiesPerModule :chartData="chartData.avgNotmetPerModule" :title="title" />
                </div>
                <div>
                    <AverageSurveyResultsByState :chartData="chartData.avgCompliancePerState" :title="title"/>
                </div>
            </div> 
            <div v-else>
                <h2 class="font-display text-base lg:text-xl text-black text-center mt-6 mb-2">Let's compare!</h2>
                <p class="text-sm text-grey-1 text-center">Select a practice name, state, MMM OR size and then a 'Compare to' option</p>
            </div> 
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import BarChartIcon from '@/assets/icons/icons_barcharts.svg'
import DataRangePicker from '@/components/partials/DateRangePicker'
import AverageSurveyResults from '@/components/analytics-report/partials/AverageSurveyResults'
import NonConformitiesPerModule from '@/components/analytics-report/partials/NonConformitiesPerModule'
import AverageSurveyResultsByState from '@/components/analytics-report/partials/AverageSurveyResultsByState'
import {format} from 'date-fns'
import camelcaseKeys from 'camelcase-keys'
import Loader from '@/assets/loader.svg'

export default {
    name: 'PracticeComparison',
    components: {DataRangePicker, BarChartIcon, AverageSurveyResults, NonConformitiesPerModule, AverageSurveyResultsByState, Loader},
    props: {
        practices: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data(){
        return {
            firstDay: format(new Date(new Date().getFullYear(), 0), 'Y-MM-dd'),
            lastDay: format(new Date(), 'Y-MM-dd'),
            errorCode: null,
            loading: false, 
            dataFilter: {},
            chartData: null,
            filterType: null,
            listFilterType: [],
            filter: {
                compare_to: ''
            },
            title: 'All my practices',
            today: format(new Date(),'yyyy-MM-dd'),
            dateRange: [],
            defaultRange: true,
            isDisabledPractice: false,
            isDisabledState: false,
            isDisabledMMM: false,
            isDisabledSize: false,
            isDisabledApply: true,
            isDisabledReset: true,
        }
    },
    mounted() { 
        this.dateRange = [
            this.firstDay,
            this.lastDay
        ]
    },
    computed: {
        ...mapState({
            staticChoices: state => state.app.staticChoices
        }),
        stateList(){
            return this.staticChoices['analytics_state'] || []
        },
        mMMList(){
            return this.staticChoices['practice.mmm'] || []
        },
        sizeList(){
            return this.staticChoices['analytics_practice_size'] || []
        },
        compareList(){
            return this.staticChoices['analytics_compare_to'] || []
        }
    },
    methods: {
        handleApply() {
            this.loading = true
            this.$http({
                method: 'POST',
                url: `/reports/analytics/multi_practice_charts/`,
                data: this.filter
            }).then(({data}) => {
                this.chartData = camelcaseKeys(data, {deep: true})
                this.errorCode = null
            })
            .catch(({ response }) => {
                if(response?.status === 404 || response?.status === 500){
                    this.errorCode = 404
                    this.loaded = false
                }
            })
            .finally(() => {
                this.loading = false
            })
        },
        setFilterType(type) {
            this.filterType = type
        },
        filterUpdated(item) {
            const arrType = ['practiceName', 'state', 'mmm', 'size']
            if(this.filterType === "compare"){
                if(this.listFilterType.indexOf('compare') < 0){
                    this.listFilterType.push('compare')
                }
                this.title = item.text
                this.filter.compare_to = item.value
                this.isDisabledReset = false
            }else if(this.filterType === "reset"){
                this.isDisabledReset = this.filterType === "reset" ? true : false
                this.$refs["selectedPractice"].reset()
                this.$refs["selectedState"].reset()
                this.$refs["selectedMMM"].reset()
                this.$refs["selectedSize"].reset()
                this.$refs["selectedCompare"].reset()
                this.dateRange = [
                    this.firstDay,
                    this.lastDay
                ]
                this.filter= { compare_to: null }
                this.listFilterType = []
                this.isDisabledPractice = false
                this.isDisabledState = false
                this.isDisabledMMM = false
                this.isDisabledSize = false
                this.defaultRange = true
            }else if(arrType.indexOf(this.filterType) >= 0 ){
                
                this.isDisabledReset = false
                // Remove value contained in this.listFilterType
                arrType.map((value) => {
                    if(this.listFilterType.indexOf(value) >= 0){
                        this.listFilterType.splice(this.listFilterType.indexOf(value), 1);
                    }
                })
                this.listFilterType.push(this.filterType)

                switch(this.filterType) {
                    case 'practiceName':
                        this.filter.practice = item
                        this.isDisabledPractice = false
                        this.isDisabledState = true
                        this.isDisabledMMM = true
                        this.isDisabledSize = true
                        break
                    case 'state' :
                        this.filter.state = item
                        this.isDisabledPractice = true
                        this.isDisabledState = false
                        this.isDisabledMMM = true
                        this.isDisabledSize = true
                        break
                    case 'mmm' :
                        this.filter.mmm = item
                        this.isDisabledPractice = true
                        this.isDisabledState = true
                        this.isDisabledMMM = false
                        this.isDisabledSize = true
                        break
                    case 'size' :
                        this.filter.size = item
                        this.isDisabledPractice = true
                        this.isDisabledState = true
                        this.isDisabledMMM = true
                        this.isDisabledSize = false
                        break
                }
            }
        },
    },
    watch: {
        dateRange(value) {
            let listFilterType = this.listFilterType
            const index = listFilterType.indexOf('dateRange')
            if(index >= 0 ){
                listFilterType = listFilterType.splice(index, 1)
            }
            if(value.length === 2){
                this.listFilterType.push('dateRange')
                this.filter.date_start = format(new Date(value[0]),'yyyy-MM-dd')
                this.filter.date_end = format(new Date(value[1]),'yyyy-MM-dd')
                this.isDisabledReset = this.defaultRange? true :false
                this.defaultRange = false
            }
        },
        listFilterType(data){
            this.isDisabledApply = data.length >= 3 ? false : true
        }
    },
}
</script>
