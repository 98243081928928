<template>
    <div>
        <div class="flex items-center my-4">
            <ArrowUpIcon class="text-primary-light w-10 mr-1" />
            <h1 class="text-black text-xl lg:text-2xl">Top 5 Practices</h1>

            <v-tooltip right>
                <template v-slot:activator="{on, attrs}">
                    <fa-icon
                        v-bind="attrs"
                        v-on="on"
                        icon="question-circle"
                        class="ml-2 mt-1 text-grey-2"
                    ></fa-icon>
                </template>
                <span>abc</span>
            </v-tooltip>
        </div>

        <router-link
            v-for="practice in stats"
            :key="`top-practice-${practice.id}`"
            :to="{name: 'Staff', params: {id: practice.id}}"
            class="flex text-sm items-center border-b justify-between p-2 cursor-pointer hover:bg-grey-8"
        >
            <div class="truncate">
                {{ practice.name }}
            </div>
            <fa-icon icon="chevron-right" class="text-xs text-grey-2"></fa-icon>
        </router-link>
    </div>
</template>

<script>
import ArrowUpIcon from '@/assets/icons/icons_arrow_up.svg'

export default {
    name: 'Top5Practices',
    components: { ArrowUpIcon },
    props: {
        stats: {
            type: Array,
            default: () => {}
        }
    }
}
</script>
