<template>
    <div>
        <div class="grid grid-cols-2 gap-4">
            <div class="col-span-3 bg-grey-7 p-4 rounded">
                <div
                    class="text-center text-black text-base xl:text-xl font-bold font-display mb-4"
                >
                    Average Survey Results
                    <v-tooltip right>
                        <template v-slot:activator="{on, attrs}">
                            <fa-icon
                                v-bind="attrs"
                                v-on="on"
                                icon="question-circle"
                                class="ml-2 text-black"
                            ></fa-icon>
                        </template>
                        <span>abc</span>
                    </v-tooltip>
                </div>
                <div>
                    <div class="grid grid-cols-2">
                        <div class="col-span-1 text-center">
                            <div v-if="chartData && chartData.left !== null && chartData.left === Object(chartData.left)">
                                <span
                                    class="text-grey-2 uppercase font-bold text-xs font-display"
                                >Selected Practice(s) Avg.</span>
                                <div ref="myAvgPerCompliance"></div>
                            </div>
                            <div v-else>
                                <h2 class="font-display text-base lg:text-xl text-black text-center mt-6 mb-2">Unable to load this chart!</h2>
                                <p class="text-sm text-grey-1 text-center">Please try again or contact your system administrator.</p>
                            </div>
                        </div>
                        <div class="col-span-1 text-center">
                            <div v-if="chartData && chartData.right !== null && chartData.right !== null && Object(chartData.right)">
                                <span
                                    class="text-grey-2 uppercase font-bold text-xs font-display"
                                >{{title}} avg.</span>
                                <div ref="allAvgPerCompliance"></div>
                            </div>
                            <div v-else>
                                <h2 class="font-display text-base lg:text-xl text-black text-center mt-6 mb-2">Unable to load this chart!</h2>
                                <p class="text-sm text-grey-1 text-center">Please try again or contact your system administrator.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {Chart} from 'frappe-charts/dist/frappe-charts.min.esm'

export default {
    name: 'AverageSurveyResults',
    props: {
        chartData: {
            type: Object,
            default: () => {
                return null
            }
        },
        title: {
            type: String,
            default: 'All my practices'
        }
    },
    data: () => ({
        chartSize: 280,
    }),
    mounted(){
        this.drawPractiveAboveBelowChart(this.chartData)
    },
    methods: {
        drawPractiveAboveBelowChart(data) {
            new Chart(this.$refs.myAvgPerCompliance, {
                data: {
                    labels: ['MET', 'NOT MET', 'N/A'],
                    datasets: [
                        {
                            values: [
                                this.round(data.left.met),
                                this.round(data.left.notMet),
                                this.round(data.left.notApplicable)
                            ]
                        }
                    ]
                },
                type: 'donut',
                height: this.chartSize,
                truncateLegends: true,
                colors: [
                    '#29AC64', // tailwind: green
                    '#E80729', // tailwind: red
                    '#FF6A00' // tailwind: risk-matrix-orange-dark
                ]
            })
            new Chart(this.$refs.allAvgPerCompliance, {
                data: {
                    labels: ['MET', 'NOT MET', 'N/A'],
                    datasets: [
                        {
                            values: [
                                this.round(data.right.met),
                                this.round(data.right.notMet),
                                this.round(data.right.notApplicable)
                            ]
                        }
                    ]
                },
                type: 'donut',
                height: this.chartSize,
                truncateLegends: true,
                colors: [
                    '#29AC64', // tailwind: green
                    '#E80729', // tailwind: red
                    '#FF6A00' // tailwind: risk-matrix-orange-dark
                ]
            })
        },
        round(val) {
            return Math.round(val * 100) / 100
        }
    },
    watch: {
        chartData(data) {
            this.drawPractiveAboveBelowChart(data)
        },
    }
}
</script>