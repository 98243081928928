<template>
    <div>
        <div class="grid grid-cols-2 gap-4">
            <div class="col-span-3 bg-grey-7 p-4 rounded">
                <div
                    class="text-center text-black text-base xl:text-xl font-bold font-display mb-4"
                >
                    Non-Conformities per Module
                    <v-tooltip right>
                        <template v-slot:activator="{on, attrs}">
                            <fa-icon
                                v-bind="attrs"
                                v-on="on"
                                icon="question-circle"
                                class="ml-2 mt-1 text-black"
                            ></fa-icon>
                        </template>
                        <span>abc</span>
                    </v-tooltip>
                </div>        
                <div>
                    <div class="grid grid-cols-2 gap-2">
                        <div class="col-span-1 text-center">
                            <div v-if="chartData && chartData.left !== null && chartData.left === Object(chartData.left)">
                                <span
                                    class="text-grey-2 uppercase font-bold text-xs font-display"
                                >Selected Practice(s) Avg.</span>
                                <Bar
                                    :chart-options="chartOptions"
                                    :chart-data="chartDataPractice"
                                    :height="chartSize"
                                />
                            </div>
                            <div v-else>
                                <h2 class="font-display text-base lg:text-xl text-black text-center mt-6 mb-2">Unable to load this chart!</h2>
                                <p class="text-sm text-grey-1 text-center">Please try again or contact your system administrator.</p>
                            </div>
                        </div>
                        <div class="col-span-1 text-center">
                            <div v-if="chartData && chartData.right !== null && chartData.right !== null && Object(chartData.right)">
                                <span
                                    class="text-grey-2 uppercase font-bold text-xs font-display"
                                >{{title}} avg.</span>
                                <Bar
                                    :chart-options="chartOptions"
                                    :chart-data="chartDataMyPractice"                        
                                    :height="chartSize"
                                />
                            </div>
                            <div v-else>
                                <h2 class="font-display text-base lg:text-xl text-black text-center mt-6 mb-2">Unable to load this chart!</h2>
                                <p class="text-sm text-grey-1 text-center">Please try again or contact your system administrator.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FileMethods from '@/components/shared/mixins/fileMethods'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import { AuthMixin } from "@/components/shared/mixins/authHelpers"
import { Bar } from 'vue-chartjs/legacy'


import {
  Chart as ChartJS,
  Tooltip,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js';

ChartJS.register(Tooltip, BarElement, CategoryScale, LinearScale)

export default {
    name: 'NonConformitiesPerModule',
    components: { Bar },
    mixins: [FileMethods, MessageDialog, AuthMixin],
    props: {
        chartData: {
            type: Object,
            default: () => {
                return null
            }
        },
        title: {
            type: String,
            default: 'All my practices'
        }
    },
    mounted() {
        this.chartDataPractice.datasets[0].data = [
            this.chartData.left.c.toFixed(2),
            this.chartData.left.gp.toFixed(2),
            this.chartData.left.qi.toFixed(2)
        ],
        this.chartDataMyPractice.datasets[0].data = [
            this.chartData.right.c.toFixed(2),
            this.chartData.right.gp.toFixed(2),
            this.chartData.right.qi.toFixed(2)
        ]
    },
    data: () => ({
        chartSize: 280,
        chartDataPractice: {
            labels: [
                'Core',
                'Quanlity Improvement',
                'General Practice'
            ],
            datasets: [
                {
                    backgroundColor: [
                    '#29AC64', // tailwind: green
                    '#E80729', // tailwind: red
                    '#FF6A00'
                    ],
                    data: [],
                    barThickness: 50
                }
            ]
        },
        chartDataMyPractice: {
            labels: [
                'Core',
                'Quanlity Improvement',
                'General Practice'
            ],
            datasets: [
                {
                    backgroundColor: [
                        '#29AC64',
                        '#E80729', 
                        '#FF6A00'
                    ],
                    data: [],
                    barThickness: 50,
                }
            ]
        },
        chartOptions: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                x: {
                    grid: {
                        display:false
                    }
                },
                y: {
                    min: 0,
                    max: 40,
                    ticks: {
                        stepSize: 2
                    }
                }
            },
            plugins: {
                tooltip: {
                    mode: 'x',
                    displayColors: false,
                    intersect: false,
                    callbacks: {
                        title: ((tooltipItems) => {
                            return 'Non-Conformities: ' + tooltipItems[0].parsed.y
                        }),
                        label: (() => {
                            return;
                        })
                    }
                }
            }  
        }
    }),
    watch: {
        chartData(data) {
            this.chartDataPractice.datasets[0].data = [
            data.left.c.toFixed(2),
            data.left.gp.toFixed(2),
            data.left.qi.toFixed(2)
        ],
        this.chartDataMyPractice.datasets[0].data = [
            data.right.c.toFixed(2),
            data.right.gp.toFixed(2),
            data.right.qi.toFixed(2)
        ]
        },
    }
}
</script>