<template>
    <div class="hidden">
        <div class="flex items-center my-4">
            <PracticesIcon class="text-primary-light w-10 mr-1" />
            <h1 class="text-black text-xl lg:text-2xl">Single Practice</h1>
        </div>
        <div class="mx-4 min-w-200 max-w-200">
            <v-autocomplete
                    hide-details
                    placeholder="Select one"
                    :items="practiceNameList"
                    label="Select a practice"
                    @click="handleSelect()"
                ></v-autocomplete>
        </div>
        <div class="flex items-center justify-center h-20 w-full" v-if="loading">
            <Loader class="w-12 h-12" />
        </div>
        <div>
            <h2 class="font-display text-base lg:text-xl text-black text-center mt-6 mb-2">Select a practice!</h2>
            <p class="text-sm text-grey-1 text-center">Select a practice name to view data.</p>
        </div>
    </div>
</template>

<script>
import PracticesIcon from '@/assets/icons/nav/practices.svg'
import Loader from '@/assets/loader.svg'
export default {
    name: 'SinglePractice',
    components: { PracticesIcon, Loader },
    data() {
        return{
            loading: false,
            practiceNameList: [
                'Meena',
                'Miranda',
                'Derby',
                'Andrew',
                'Ken',
                'Henry'
            ],
        }
    },
    methods: {
        handleSelect() {
            this.loading = true
        }
    }
}
</script>
