<template>
    <div class="container py-4">
        <div v-if="errorCode === 500"
            class="text-center"
        >
            <h1 class="text-black text-lg lg:text-2xl text-center mb-3 mt-4">Unable to load this page!
            </h1>
            <p>Please try again by refreshing or contact your system administrator.</p>
            <img src="@/assets/images/error.png" class="max-w-500 m-auto" :style="imgStyle" alt="Error" />
        </div>
        <div v-else>
            <div v-if="!loading">
                <div class="grid grid-cols-1 xl:grid-cols-3 gap-4 mt-4">
                    <TopRatedIndicators :stats="stats.topRatedIndicators" class="col-span-1"/>
                    <Top5Practices :stats="stats.top5Practices" class="col-span-1"/>
                    <Lowest5Practices :stats="stats.bottom5Practices" class="col-span-1"/>
                </div>
                <PracticeComparison :practices="practices" class="mt-4" />
                <SinglePractice class="mt-4" />
            </div>
            <div class="flex items-center justify-center h-20 w-full" v-else>
                <Loader class="w-12 h-12" />
            </div>
        </div>
    </div>
</template>

<script>
import TopRatedIndicators from '@/components/analytics-report/partials/TopRatedIndicators.vue'
import Top5Practices from '@/components/analytics-report/partials/Top5Practices.vue'
import Lowest5Practices from '@/components/analytics-report/partials/Lowest5Practices.vue'
import PracticeComparison from '@/components/analytics-report/partials/PracticeComparison.vue'
import SinglePractice from '@/components/analytics-report/partials/SinglePractice.vue'
import Loader from '@/assets/loader.svg'
import camelcaseKeys from 'camelcase-keys'

export default {
    name: "AnalyticsReports",
    components: { TopRatedIndicators, Top5Practices, Lowest5Practices, SinglePractice, PracticeComparison, Loader },
    data: () => ({
        errorCode: '',
        loading: true,
        stats: {},
        chartData: {},
        practices: []
    }),
    mounted() {
        this.$http
            .get(`/reports/analytics/overview/`)
            .then(({data}) => {
                this.stats = camelcaseKeys(data, {deep: true})
                this.errorCode = ''
            })
            .catch(({ response }) => {
                if(response?.status === 403){
                    this.$router.push({ path: '/Dashboard' })
                }
                if(response?.status !== 403 || response?.status !== 200) {
                    this.errorCode = 500
                    this.loading = false
                }
            })
            .finally(() => {
                this.loading = false
            })
        
        this.$http
            .get(`/reports/analytics/practices_for_user/`)
            .then(({data}) => {
                this.practices = Object.entries(camelcaseKeys(data, {deep: true})).map((item) => {
                    return {
                        value: Number(item[0]),
                        text: item[1]
                    }
                })
            })
    }
}
</script>
