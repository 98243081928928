<template>
    <div>
        <div class="flex items-center my-4">
            <IndicatorsIcon class="text-primary-light w-10 mr-1" />
            <h1 class="text-black text-xl lg:text-2xl">Top Rated Indicators</h1>

            <v-tooltip right>
                <template v-slot:activator="{on, attrs}">
                    <fa-icon
                        v-bind="attrs"
                        v-on="on"
                        icon="question-circle"
                        class="ml-2 mt-1 text-grey-2"
                    ></fa-icon>
                </template>
                <span>abc</span>
            </v-tooltip>
        </div>
        <div class="flex items-center justify-between p-2 border-b">
            <div class="font-display text-green font-bold mr-8">
                Most Met
            </div>
            <div class="flex items-center justify-between">
                <div
                    class="text-grey-1 font-bold"
                >
                    {{ stats.met[0].indicatorCode }}
                    <v-tooltip right>
                        <template v-slot:activator="{on, attrs}">
                            <fa-icon
                                v-bind="attrs"
                                v-on="on"
                                icon="info-circle"
                                class="ml-1 text-sm text-grey-2"
                            ></fa-icon>
                        </template>
                        <span>{{ stats.met[0].indicatorText }}</span>
                    </v-tooltip>
                </div>
                <template>
                    <v-dialog
                    max-width="600"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                            color="#fff"
                            depressed
                            text
                            v-bind="attrs"
                            v-on="on"
                            ><div class="text-primary-light uppercase font-bold text-xs">
                                    View top 5
                                    <fa-icon icon="chevron-right" class="text-xs text-primary-light"></fa-icon>
                            </div></v-btn>
                        </template>
                        <template v-slot:default="dialog">
                            <v-card>
                            <h3 class="text-center font-bold mt-4 mb-2">Top 5 indicators rated as 'Most Met'</h3>
                            <div class="px-6">
                                <div v-for="(item, index) in stats.met" :key="index">
                                    <div class="grid grid-cols-12 gap-1 my-4 pb-4 border-b items-start">
                                        <div class="col-span-3 flex items-center">
                                            <div class="font-bold mr-5 text-sm">{{index + 1}}.</div>
                                            <div
                                            class="text-grey-1 font-bold"
                                            >
                                                {{item.indicatorCode}}
                                            </div>
                                        </div>
                                        <div class="col-span-8 text-sm text-grey-1"><p>{{item.indicatorText}}</p></div>
                                    </div>
                                </div>
                            </div>
                            <v-card-actions class="flex flex-row pb-4 justify-center">
                                <v-btn
                                    depressed
                                    :ripple="false"
                                    color="default" 
                                    @click="dialog.value = false"
                                >
                                    Close
                                </v-btn>
                            </v-card-actions>
                            </v-card>

                        </template>
                    </v-dialog>
                </template>
            </div>
        </div>
        <div class="flex items-center justify-between p-2 border-b">
            <div class="font-display text-red font-bold">
                Most Not Met
            </div>
            <div class="flex items-center justify-between">
                <div
                    class="text-grey-1 font-bold"
                >
                    {{ stats.notMet[0].indicatorCode }}
                    <v-tooltip right>
                        <template v-slot:activator="{on, attrs}">
                            <fa-icon
                                v-bind="attrs"
                                v-on="on"
                                icon="info-circle"
                                class="ml-1 text-sm text-grey-2"
                            ></fa-icon>
                        </template>
                        <span>{{ stats.notMet[0].indicatorText }}</span>
                    </v-tooltip>
                </div>
                <template>
                    <v-dialog
                    max-width="600"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                            color="#fff"
                            depressed
                            text
                            v-bind="attrs"
                            v-on="on"
                            ><div class="text-primary-light uppercase font-bold text-xs">
                                    View top 5
                                    <fa-icon icon="chevron-right" class="text-xs text-primary-light"></fa-icon>
                            </div></v-btn>
                        </template>
                        <template v-slot:default="dialog">
                            <v-card>
                            <v-card-text>
                                <h6 class="text-center font-bold mt-4">Top 5 indicators rated as 'Most Met'</h6>
                            </v-card-text>
                            <div class="container">
                            <div v-for="(item, index) in stats.notMet" :key="index">
                                <div class="grid grid-cols-11 gap-1">
                                    <div class="font-bold">{{index + 1}}.</div>
                                    <div
                                    class="col-span-2 text-grey-1 font-bold"
                                    >
                                        {{item.indicatorCode}}
                                    </div>
                                    <div class="col-span-8"><p>{{item.indicatorText}}</p></div>
                                </div>
                            </div>
                            </div>
                            <v-card-actions class="flex flex-row pb-4 justify-center">
                                <v-btn
                                    depressed
                                    :ripple="false"
                                    color="default"
                                    @click="dialog.value = false" 
                                >
                                    Close
                                </v-btn>
                            </v-card-actions>
                            </v-card>
                        </template>
                    </v-dialog>
                </template>
            </div>
        </div>
        <div class="flex items-center justify-between p-2 border-b">
            <div class="font-display text-orange font-bold mr-8">
                Most N/A
            </div>
            <div class="flex items-center justify-between">
                <div
                    class="text-grey-1 font-bold"
                >
                    {{ stats.notApplicable[0].indicatorCode }}
                    <v-tooltip right>
                        <template v-slot:activator="{on, attrs}">
                            <fa-icon
                                v-bind="attrs"
                                v-on="on"
                                icon="info-circle"
                                class="ml-1 text-sm text-grey-2"
                            ></fa-icon>
                        </template>
                        <span>
                            {{stats.notApplicable[0].indicatorText}}
                        </span>
                    </v-tooltip>
                </div>
                <template>
                    <v-dialog
                    max-width="600"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                            color="#fff"
                            depressed
                            text
                            v-bind="attrs"
                            v-on="on"
                            ><div class="text-primary-light uppercase font-bold text-xs">
                                    View top 5
                                    <fa-icon icon="chevron-right" class="text-xs text-primary-light"></fa-icon>
                            </div></v-btn>
                        </template>
                        <template v-slot:default="dialog">
                            <v-card>
                            <v-card-text>
                                <h6 class="text-center font-bold mt-4">Top 5 indicators rated as 'Most Met'</h6>
                            </v-card-text>
                            <div class="container">
                            <div v-for="(item, index) in stats.notApplicable" :key="index">
                                <div class="grid grid-cols-11 gap-1">
                                    <div class="font-bold">{{index + 1}}.</div>
                                    <div
                                    class="col-span-2 text-grey-1 font-bold"
                                    >
                                        {{item.indicatorCode}}
                                    </div>
                                    <div class="col-span-8"><p>{{item.indicatorText}}</p></div>
                                </div>
                            </div>
                            </div>
                            <v-card-actions class="flex flex-row pb-4 justify-center">
                                <v-btn
                                    depressed
                                    :ripple="false"
                                    color="default"
                                    @click="dialog.value = false" 
                                >
                                    Close
                                </v-btn>
                            </v-card-actions>
                            </v-card>
                        </template>
                    </v-dialog>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import IndicatorsIcon from '@/assets/icons/icons_indicators.svg'

export default {
    name: 'TopRatedIndicators',
    components: { IndicatorsIcon },
    props: {
        stats: {
            type: Object,
            default: () => {
            }
        }
    }
}
</script>